/*************************************************************
**************************************************************
	This is the main wackadoo-dark-mode SCSS overlay...
**************************************************************
**************************************************************/

.modal-splash {
  background-color: $body-bg;
  border-color: $green;
  border-width: 1px;
	box-shadow: 0.5rem 0.5rem 0.5rem $dark-gray;
}

.navbar
{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.navBarLogo
{
  margin-top: 0.25em;
}

.footer-content
{
  // same as smallish... (the white Copyright looks bigger than the green link without this...)
	font-size: 0.85em !important;
}

a
{
  color: green;
}

a:hover  
{
  color: green;
  // simple links could be on any bg color...
  // background-color: $body-bg;
  text-decoration: bold;
}

.link {
    background-color: inherit !important;
}

.navbar .nav-link 
{
    background-color: $body-bg;
    border: none;
}

.nav-link:hover 
{
  background-color: $body-bg;
  text-decoration: bold;
}

.dropdown-item:hover  
{
  color: green;
  background-color: $body-bg;
  text-decoration: bold;
}

// So we use the dark mode toggler in the banner
// i.e. white links against a dark background...
// but we want to make the lines brighter than default.

// the border around the icon...
.navbar-toggler {
  border-color: $gray;
}
// the lines in the icon...
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$body-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;

@media screen and (max-width:1180.1px) {
    .nav-link
    {
        font-size: 1em;
    }
}

@media screen and (max-width:767px) {

    .navbar .nav-link 
    {
        background-color: $body-bg;
        border: 1px solid green;
    }

    .nav-link:hover 
    {
      background-color: $gray;
      text-decoration: bold;
    }

}

/**************************************************************
   These are ALL the color styles for all 
   text, borders, etc. from wackadoo.scss
***************************************************************/

.actionButton,
button.btn.btn-secondary,
button.btn.btn-secondary.actionButton
{
	color: $body-bg;
	background-color: $gray;
}

.titleOrAbbrevBeforeIcon,
.titleOrAbbrevAfterIcon
{
	color: $body-bg;
}

/*** link stuff ***/

.link
{
	color: $wackadoo-other-green; 
}

.linkblue
{
	color : lightblue; 
}

.rollover-lightgray-bg:hover
{
	background-color : $light-gray;
}

.selected
{
	background-color: beige;
}

/*** borders ***/

.shadow
{
  box-shadow: 0.75rem 0.75rem 0.75rem $dark-gray !important;
}

.border
{
	border-left-color: $dark-gray !important;
	border-right-color: $dark-gray !important;
	border-top-color: $dark-gray !important;
	border-bottom-color: $dark-gray !important;
}

.border-left
{
	border-left-color: $dark-gray !important;
}

.border-right
{
	border-right-color: $dark-gray !important;
}

.border-top
{
	border-top-color: $dark-gray !important;
}

.border-bottom
{
	border-bottom-color: $dark-gray !important;
}

.wrap-scrollable-table th
{
	background: $dark-gray;
}

tr.numbered-row th:first-child 
{
	background-color: $body-bg;
}

button.btn.btn-outline-secondary.wackadoo-grid-control,
button.btn.btn-outline-secondary.wackadoo-grid-search-control
{
	color: $body-bg;
	background-color: $gray;
	border: 1px solid $light-gray;
}

input.wackadoo-grid-search-control
{
	color: $body-bg;
	border: 1px solid $light-gray;
}

.wackadoo-grid-control:hover,
.wackadoo-grid-search-control:hover
{
	filter: invert(15%);
}

.fieldSelected,
.fieldSelected .ngb-tp-input.form-control
{
	color : $green ;
}

.fieldChanged,
.fieldChanged:focus,
.fieldChanged .ngb-tp-input.form-control
{
	border-color : $cyan 2px !important;
}

wackadoo-field .form-check-input.fieldChanged 
{
	outline: $cyan solid 2px !important;
}

/* NOTE: This placeholder class does NOT appear to work... */

.fieldChanged::placeholder,
.fieldChanged::-webkit-placeholder,       /* Chrome/Opera/Safari ?? */
.fieldChanged::-webkit-input-placeholder, /* Chrome/Opera/Safari ?? */
.fieldChanged::-moz-placeholder,          /* Firefox 19+ */
.fieldChanged:-ms-input-placeholder,      /* IE 10+ */
.fieldChanged:-moz-placeholder,           /* Firefox 18- */
.fieldChanged .ngb-tp-input.form-control::placeholder,
.fieldChanged .ngb-tp-input.form-control::-webkit-placeholder,       /* Chrome/Opera/Safari ?? */
.fieldChanged .ngb-tp-input.form-control::-webkit-input-placeholder, /* Chrome/Opera/Safari ?? */
.fieldChanged .ngb-tp-input.form-control::-moz-placeholder,          /* Firefox 19+ */
.fieldChanged .ngb-tp-input.form-control:-ms-input-placeholder,      /* IE 10+ */
.fieldChanged .ngb-tp-input.form-control:-moz-placeholder            /* Firefox 18- */
{
  border-color: $pink !important;
}

.fieldError,
.fieldError .ngb-tp-input.form-control
{
	border-color : $red !important;
	background-color: $yellow !important;
}

.sideNavMenu {
  background-color: transparent;
}

.sideNavMenu nav-link
{
    background-color: $light-gray;
}

.navBarAppName
{
    color: $body-color;
}

.nav-item .dropdown-menu
{
    background-color: transparent;
}

.dropdown-header
{
  color: white;
  background-color: $body-bg;
  border: 1px solid $gray;
}

a.dropdown-item 
{
  color: $green;
  background-color: $body-bg;
  border: 1px solid $gray;
}

a.dropdown-item:hover
{
  text-decoration: bold;
} 

.modal-backdrop {
	background: $gray;
}

.modal-box {
	background-color: $body-bg;
	// border: 1px solid $green !important;
	box-shadow: 0.75rem 0.75rem 0.75rem $dark-gray;
}

.card-header {
	background-color: $gray;
}

.well
{
	background-color: $gray;
}


/**************************************************************
   These are other color styles for things 
   that were NOT in wackadoo.scss
***************************************************************/

.card
{
  color: black;
  background-color: $light-gray;
}

.jumbotron
{
    background-color: slategray;
}

.form-control:disabled, .form-control[readonly]
{
  background-color: $gray;
}
