/*************************************************************
**************************************************************
	AccountManager stylesheet
**************************************************************
**************************************************************/

/* These are not used in dark-mode in general, but used in this file... */

$light-gray: #dee2e6 !default; // same as $gray-300;
$gray:       #adb5bd !default; // same as $gray-500;
$dark-gray:  #495057 !default; // same as $gray-700;

/****************************************************************
  Things that should be moved to wackadoo.scss, especially when 
  we re-do the info/org/closingpro home page to match this one 
****************************************************************/

.carousel-caption
{
  // this centers the caption vertically in the image...
  top: 50%;
  transform: translateY(-50%);
  // Now you need to get rid of the extra bottom space, added by the default bootstrap CSS.
  bottom: initial;
  padding-top: 0px;
}

@media screen and (max-width:500px) {
  .carousel-caption
  {
    // this bumps the caption a little higher in the image on phones...
    top: 33%;
    transform: translateY(-33%);
  }
}


a.nav-link
{
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
		
/*
  Polaroid style pictures...

  <div class="polaroid rotate_right">
    <img src="pulpitrock.jpg" alt="Pulpit rock" width="284" height="213">
    <p>The pulpit rock in Lysefjorden, Norway.</p>
  </div>

*/

div.polaroid {
  padding: 1em;
  border: 1px solid $gray;
  background-color: $body-color;
	box-shadow: 0.75rem 0.75rem 0.75rem $dark-gray;
}

div.rotate_right {
  transform: rotate(7deg);
}

div.rotate_left {
  transform: rotate(-8deg);
}

div.polaroid > div,
div.polaroid > p
{
  color: $body-bg;
  text-align: center;
  padding-top: 0.5em;
}

/*
  a diagnoal watermark across the page...

   <div class="watermark">WATERMARK</div>


*/

 .watermark
 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-30deg);
  color: rgba(255, 255, 0, 67%);
  pointer-events: none;
  z-index: 9999;
}

.watermark.fullscreen
{
  font-size: 7em;
}

.watermark.tablet
{
  font-size: 4.5em;
}

.watermark.phone
{
  font-size: 2em;
}

